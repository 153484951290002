// http://bitwiser.in/medium-style-confirm/

var tags = [];

(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        define([], factory);
    } else if (typeof module === 'object' && module.exports) {
        module.exports = factory();
    } else {
        var expData = factory();
        for (var key in expData) {
            if (expData.hasOwnProperty(key)) {
                root[key] = expData[key];
            }
        }
    }
}(this, function () {
    function ce(tag, clas, txt) {
        var ele = document.createElement(tag);
        ele.setAttribute('class', clas);
        if (typeof txt === 'undefined' || txt === null) {
            return ele;
        }
        //var tn = document.createTextNode(txt);
        //ele.appendChild(tn);
        ele.innerHTML = txt;
        return ele;
    }
    var KEY_ESC = 27;
    var KEY_ENTER = 13;

    function buildUI(title, sub, onOk, onCancel, onConvert, type, okText = 'OK') {
        // Clear global variable
        // CUSTOM
        tags = [];
        let phoneController;
        if (typeof window === 'undefined') {
            throw 'Cannot use this in node.';
        }
        var prev = document.getElementsByClassName('msc-confirm');
        if (prev.length > 0) {
            document.body.removeChild(prev[0]);
        }

        var options = {
            title: 'Confirm',
            subtitle: '',
            onOk: null,
            onCancel: null,
            onConvert: null, // CUSTOM
            onRemoveVideo: null, // CUSTOM
            okText: okText,
            cancelText: 'Cancel',
            placeholder: 'Enter value',
            dismissOverlay: false,
            showConvertToYelpButton: true, // CUSTOM
            defaultValue: '',
            categoryTitle: '', // CUSTOM
            categoryResponse: '', // CUSTOM
            categoryTag: '', // CUSTOM
            categoryTags: [], // CUSTOM
            hasChildren: false, // CUSTOM
            callbackOffer: false, // CUSTOM
            specialOffer: '', // CUSTOM
            picture: '', // CUSTOM
            audio: '', // CUSTOM
            video: '', // CUSTOM
            tier: null, // CUSTOM
            telephoneRequired: false, // CUSTOM
            emailRequired: false, // CUSTOM
            containerId: null, // CUSTOM
            site: 0, // CUSTOM
            isGlobal: false, // CUSTOM
            groupCategories: [], // CUSTOM
            selectedGroupCategories: [] // CUSTOM
        };

        if (typeof title === 'object') {
            for (var key in title) {
                options[key] = title[key];
            }
            if (typeof options.onOk !== 'function') {
                options.onOk = null;
            }
            if (typeof options.onCancel !== 'function') {
                options.onCancel = null;
            }
        } else {
            options.title = typeof title === 'string' ? title : options.title;
            options.subtitle = typeof sub === 'string' ? sub : options.subtitle;
            options.onOk = typeof onOk === 'function' ? onOk : options.onOk;
            options.onCancel = typeof onCancel === 'function' ? onCancel : options.onCancel;
            // CUSTOM
            options.onConvert = typeof onConvert === 'function' ? onConvert : options.onConvert;
            options.onRemoveVideo = typeof onRemoveVideo === 'function' ? onRemoveVideo : options.onRemoveVideo;

            if (typeof sub === 'function') {
                options.onOk = sub;
            }
        }

        var dialog = ce('div', 'msc-confirm'),
            overlay = ce('div', 'msc-overlay'),
            closeBtn = ce('button', 'msc-close');
        closeBtn.innerHTML = '&times;';
        overlay.appendChild(closeBtn);

        if (options.dismissOverlay) {
            overlay.addEventListener("click", destroy);
        }

        closeBtn.addEventListener('click', destroy);

        var content = ce('div', 'msc-content'),
            cTitle = ce('h3', 'msc-title', options.title),
            body = ce('div', 'msc-body'),
            action = ce('div', 'msc-action'),
            okBtn = ce('button', 'msc-ok', options.okText),
            cancelbtn = ce('button', 'msc-cancel', options.cancelText),
            convertBtn = ce('button', 'msc-convert', 'Connect to Yelp'), // CUSTOM
            removePictureBtn = ce('button', 'msc-canel', 'Remove Picture'), // CUSTOM
            removeAudioBtn = ce('button', 'msc-canel', 'Remove Audio'), // CUSTOM
            removeVideoBtn = ce('button', 'msc-cancel', 'Remove Video'), // CUSTOM
            input = ce('input', 'msc-input'),
            textarea = ce('textarea', 'msc-textarea'), // CUSTOM
            tag = ce('input', 'msc-input'), // CUSTOM
            specialOfferInput = ce('input', ''), // CUSTOM
            pictureFile = ce('input', ''), // CUSTOM
            audioFile = ce('input', ''), // CUSTOM
            videoFile = ce('input', ''), // CUSTOM
            importFile = ce('input', ''), // CUSTOM
            contactDescription = ce('input', 'msc-input'), // CUSTOM
            contactTelephone = ce('input', 'msc-input'), // CUSTOM
            contactEmail = ce('input', 'msc-input'); // CUSTOM

        body.appendChild(ce('p', 'msc-sub', options.subtitle));

        action.appendChild(okBtn);

        // CUSTOM
        if (type === "category" && options.title.substr(0, 4) === "Edit" && !options.hasChildren) {
            // Only show the Yelp button for VCS
            if (!(categoryBox.hasPicture(options) || categoryBox.hasVideo(options)) &&
                options.showConvertToYelpButton &&
                options.site === 0) {
                action.appendChild(convertBtn);
                convertBtn.addEventListener('click', convert);
            } 
        }

        // CUSTOM
        if (categoryBox.hasPicture(options)) {
            removePictureBtn.addEventListener('click', removePicture);
        }

        // CUSTOM
        if (categoryBox.hasAudio(options)) {
            removeAudioBtn.addEventListener('click', removeAudio);
        } 

        // CUSTOM
        if (categoryBox.hasVideo(options)) {
            removeVideoBtn.addEventListener('click', removeVideo);
        } 

        if (type !== "alert") {
            action.appendChild(cancelbtn);
            cancelbtn.addEventListener('click', cancel);
        }

        okBtn.addEventListener('click', ok);

        content.appendChild(cTitle);
        content.appendChild(body);
        content.appendChild(action);

        dialog.appendChild(overlay);
        dialog.appendChild(content);
        document.body.appendChild(dialog);
        dialog.style.display = 'block';
        content.classList.add('msc-confirm--animate');
        if (type === "prompt") {
            input.setAttribute("type", "text");
            input.setAttribute("placeholder", options.placeholder);
            input.value = options.defaultValue;
            input.addEventListener("keyup", function (e) {
                if (e.keyCode === KEY_ENTER) {
                    ok();
                }
            });
            body.appendChild(input);
            input.focus();
        // CUSTOM
        } else if (type === "category") {
            input.setAttribute("type", "text");
            input.setAttribute("placeholder", "Enter the title");
            input.setAttribute("onkeyup", "categoryBox.checkForBannedCharacters(this)");
            input.value = options.categoryTitle;
            body.appendChild(input);

            input.focus();
            tag.setAttribute("text", "type");
            tag.setAttribute("placeholder", "Enter a tag (optional)");
            tag.setAttribute("id", "tag");
            body.appendChild(tag);

            var tagsPanel = document.createElement('div');
            tagsPanel.className = 'tags-panel clearfix margin-top-reg';
            tagsPanel.innerText = 'Tags that have been added will be displayed here';
            body.appendChild(tagsPanel);

            // Prepopulate tags
            if (options.categoryTag !== undefined && options.categoryTag.length > 0) {
                tags.push(options.categoryTag);
            }
            else {
                tags = options.categoryTags;
                if (tags === undefined) {
                    tags = [];
                }
            }

            categoryBox.displayTags(tags);

            $('#tag').autocomplete({
                delay: 0,
                minLength: 0,
                source: function (request, response) {
                    $.getJSON('/api/CategoryTags', {
                        q: request.term
                    }, response);
                },
                select: function (event, ui) {
                    if (tags === null) {
                        tags = [];
                    }
                    categoryBox.addTag(ui.item.value, tags);
                    $('#tag').val('');
                },
                change: function (event, ui) {
                    // Only allow entries from autocomplete
                    if (ui.item === null) {
                        $('#tag').val('');
                        $('#tag').focus();
                    }
                    else {
                        if (tags === null) {
                            tags = [];
                        }
                        categoryBox.addTag(ui.item.value, tags);
                        $('#tag').val('');
                    }
                }
            }).focus(function () {
                $(this).data('uiAutocomplete').search($(this).val());
            });

            textarea.setAttribute("placeholder", "Enter the content");
            textarea.setAttribute("onkeyup", "categoryBox.updateRecommendedCharacters(this, " + options.hasChildren + ");categoryBox.checkForBannedCharacters(this)");
            textarea.value = options.categoryResponse;
            body.appendChild(textarea);

            // Recommended Characters Remaining
            var popoverMessage = options.hasChildren ? 'Voice experiences are different than reading text. Your guests want valuable information, but have short attention spans! This item has options below it, so your description should be very brief. Keeping your inputs under 100 characters will allow your information to be conveyed in less than 10 seconds so that your guests can then hear the other choices you have defined.' : 'Voice experiences are different than reading text. Your guests want valuable information, but have short attention spans! Keeping your inputs under 500 characters will allow your information to be conveyed in less than 30 seconds.';
            var recommendedLength = options.hasChildren ? categoryBox.parentLength : categoryBox.childLength;
            var charCount = recommendedLength - textarea.value.length;
            var fontColor = charCount < 0 ? categoryBox.warningColor : 'inherit';
            textarea.setAttribute("style", "color: " + fontColor + ";");
            var div = document.createElement('div');
            div.style.fontSize = '0.75em';
            div.innerHTML = '<button class="warning-icon" data-container="body" data-toggle="popover" data-placement="right" data-content="' + popoverMessage + '"><span class="text-danger glyphicon glyphicon-warning-sign"></span></button> Recommended Characters Remaining: <span id="charCount" style="color: ' + fontColor + ';">' + charCount + '</span>';
            body.appendChild(div);

            // Special Character Error
            var errorDiv = document.createElement('div');
            errorDiv.id = 'bannedCharacters';
            errorDiv.style.display = 'none';
            errorDiv.style.fontSize = '0.75em';
            errorDiv.style.color = categoryBox.warningColor;
            errorDiv.innerHTML = 'The & character is not allowed';
            body.appendChild(errorDiv);

            // Group categories
            if (options.isGlobal) {
                var categoriesLabel = document.createElement('div');
                categoriesLabel.id = 'categoriesLabel';
                categoriesLabel.innerHTML = '<div class="clearfix"><div class="pull-left"><strong>Categories</strong></div><div class="pull-right"><a href="javascript:;" onclick="categoryBox.selectAllCategories(true)">select all</a> | <a href="javascript:;" onclick="categoryBox.selectAllCategories(false)">deselect all</a></div></div>';
                body.appendChild(categoriesLabel);
                var categoriesDiv = document.createElement('div');
                categoriesDiv.id = 'categoriesPanel';
                for (let i = 0; i < options.groupCategories.length; i++) {
                    let category = options.groupCategories[i];
                    let categoryDiv = document.createElement('div');
                    if (categoryBox.isCategoryPreselected(options.selectedGroupCategories, category.id)) {
                        categoryDiv.innerHTML = '<input type="checkbox" value="' + category.id + '" checked /> ' + category.categoryName;
                    }
                    else {
                        categoryDiv.innerHTML = '<input type="checkbox" value="' + category.id + '" /> ' + category.categoryName;
                    }
                    categoriesDiv.appendChild(categoryDiv);
                }
                // Clear the array to remove duplicates
                options.selectedGroupCategories = [];
                body.appendChild(categoriesDiv);
            }

            // More options
            var moreOptionsLink = document.createElement('div');
            moreOptionsLink.innerHTML = '<small><a id="moreOptionsLink" href="javascript:;" onclick="categoryBox.showMoreOptions()">Show More Options</a></small>';
            body.appendChild(moreOptionsLink);

            var moreOptions = document.createElement('div');
            moreOptions.id = 'moreOptions';

            // Check access
            if (options.tier !== null && options.tier < 2) {
                var accessDiv = document.createElement('div');
                accessDiv.innerHTML += '<div class="alert alert-warning"><small><span class="glyphicon glyphicon-warning-sign"></span> To leverage advanced features like creating special offers and uploading pictures, audio files, and videos, please <a href="/account#!/subscription">upgrade your account</a>. As part of the Basic Tier, you may continue to create special offers and upload files, but they will not be visible to your guests until you have upgraded.<small></div>';
                moreOptions.appendChild(accessDiv);
            }

            // Call-back option
            if (options.isGlobal || options.containerId !== null) {
                let callbackDiv = document.createElement('div');
                callbackDiv.id = 'callbackPanel';
                callbackDiv.className = 'callback-panel margin-top-reg';
                let checked = options.callbackOffer ? 'checked' : '';
                callbackDiv.innerHTML = '<input type="checkbox" id="callbackOffer" ' + checked + ' /> <span class="callback-title">Offer a call-back</span>';
                callbackDiv.innerHTML += '<div><small>If there is a call center email specified in the group settings, then the guest will receive a call-back option after hearing the contents of this item. (Call-back offers cannot be combined with Digital Coupons, Audio, or Video files)</small></div>';
                moreOptions.appendChild(callbackDiv);
            }

            // Special offer
            if (!options.hasChildren && (options.site === 0 || options.site === 1)) {
                let offerDiv = document.createElement('div');
                offerDiv.id = 'specialOfferPanel';
                offerDiv.className = 'offers-panel margin-top-reg';
                let host = siteSettings.environment === 'Production' ? 'www.virtualconciergeservice.com' : 'qa.virtualconciergeservice.com';
                offerDiv.innerHTML = '<div>Add a Digital Coupon</div>';
                offerDiv.innerHTML += `<div class="margin-bottom-reg"><small>A digital coupon is a discount or other benefit that your guests will receive at this establishment. For example, "15% off cocktails", "a free dessert with your meal", or "rent 2 bikes, get one free." If you add one to this item, it will be shared with guests after your custom content is delivered. If they would like to take advantage of it, a digital coupon will be provided via text message. <a href="https://${host}/support/providing-digital-coupons-to-guests" target="_blank">Click here</a> for more information.</small></div>`;
                specialOfferInput.setAttribute('type', 'text');
                specialOfferInput.setAttribute('placeholder', 'e.g. 15% off cocktails');
                specialOfferInput.value = !categoryBox.hasSpecialOffer(options) ? '' : options.specialOffer;
                offerDiv.appendChild(specialOfferInput);
                let removeDiv = document.createElement('div');
                removeDiv.innerHTML = '<div><small>To remove the digital coupon, simply clear the text box.</small></div>';
                offerDiv.appendChild(removeDiv);
                let specialOfferError = document.createElement('div');
                specialOfferError.id = 'specialOfferError';
                offerDiv.appendChild(specialOfferError);
                moreOptions.appendChild(offerDiv);
            }

            // Upload a picture
            var pictureDiv = document.createElement('div');
            pictureDiv.className = 'picture-panel margin-top-reg';

            if (!categoryBox.hasPicture(options)) {
                pictureDiv.innerHTML += '<div>Upload Background Image</div>';
                pictureDiv.innerHTML += '<div class="margin-bottom-reg"><small>You can choose to attach a background image to this item. The image will be viewable on Amazon Echo devices with screens, such as the Echo Show. Suggested file size: 500KB or smaller.</small></div>';
                pictureFile.setAttribute('type', 'file');
                pictureFile.id = 'pictureFile';
                pictureDiv.appendChild(pictureFile);
                let pictureError = document.createElement('div');
                pictureError.id = 'pictureError';
                pictureDiv.appendChild(pictureError);
            }
            else {
                pictureDiv.innerHTML += '<div>This item has a <a href="https://images.virtualconciergeservice.com/' + options.picture + '" target="_blank">picture</a> attached to it.</div>';
                let ele = document.createElement('div');
                ele.className = 'msc-action';
                ele.appendChild(removePictureBtn);
                pictureDiv.appendChild(ele);
            }

            moreOptions.appendChild(pictureDiv);

            // Addition options
            if (!options.hasChildren) {
                // Upload an audio
                var audioDiv = document.createElement('div');
                audioDiv.className = 'audio-panel margin-top-reg';

                if (!categoryBox.hasAudio(options)) {
                    audioDiv.innerHTML = '<div>Upload Audio File (MP3)</div>';
                    audioDiv.innerHTML += '<div class="margin-bottom-reg"><small>You can choose to upload an audio file. By doing so, your audio file will be played instead of your custom content. The audio file cannot be longer than 240 seconds. Max file size: 5MB.</small></div>';
                    audioFile.setAttribute('type', 'file');
                    audioFile.id = 'audioFile';
                    audioDiv.appendChild(audioFile);
                    let audioError = document.createElement('div');
                    audioError.id = 'audioError';
                    audioDiv.appendChild(audioError);
                    let progressBar = document.createElement('div');
                    progressBar.className = 'upload-progress-bar-audio';
                    audioDiv.appendChild(progressBar);
                }
                else {
                    audioDiv.innerHTML += '<div>This item has <a href="https://images.virtualconciergeservice.com/' + options.audio + '" target="_blank">audio</a> attached to it.</div>';
                    let ele = document.createElement('div');
                    ele.className = 'msc-action';
                    ele.appendChild(removeAudioBtn);
                    audioDiv.appendChild(ele);
                }

                // Upload a video
                let videoDiv = document.createElement('div');
                videoDiv.className = 'video-panel margin-top-reg';

                if (!categoryBox.hasVideo(options)) {
                    videoDiv.innerHTML += '<div>Upload Video File (MP4)</div>';
                    videoDiv.innerHTML += '<div class="margin-bottom-reg"><small>You can choose to upload a video file. By doing so, your video will be displayed to your guest on capable devices, such as the Echo Show. If the device does not have video capabilities, then the text you provided for custom content will be presented instead. Max video size: 100MB.</small></div>';
                    videoFile.setAttribute('type', 'file');
                    videoFile.id = 'videoFile';
                    videoDiv.appendChild(videoFile);
                    let videoError = document.createElement('div');
                    videoError.id = 'videoError';
                    videoDiv.appendChild(videoError);
                    let progressBar = document.createElement('div');
                    progressBar.className = 'upload-progress-bar-video';
                    videoDiv.appendChild(progressBar);
                }
                else {
                    videoDiv.innerHTML += '<div>This item has a <a href="https://images.virtualconciergeservice.com/' + options.video + '" target="_blank">video</a> attached to it.</div>';
                    let ele = document.createElement('div');
                    ele.className = 'msc-action';
                    ele.appendChild(removeVideoBtn);
                    videoDiv.appendChild(ele);
                }

                moreOptions.appendChild(audioDiv);
                moreOptions.appendChild(videoDiv);
            }

            body.appendChild(moreOptions);
        } else if (type === "alert") {
            okBtn.focus();
        } else if (type === "import") {
            let warningDiv = document.createElement('div');
            warningDiv.innerHTML = '<strong>Warning:</strong> Importing from a file will completely replace your existing content. This process is not reversible.';
            body.appendChild(warningDiv);

            let fileDiv = document.createElement('div');
            fileDiv.className = 'margin-top-reg';
            importFile.setAttribute('type', 'file');
            fileDiv.appendChild(importFile);
            body.appendChild(fileDiv);

            let errorDiv = document.createElement('div');
            errorDiv.id = 'importFileError';
            errorDiv.className = 'margin-top-reg';
            errorDiv.style.display = 'none';
            body.appendChild(errorDiv);
        } else if (type === "export") {
            input.setAttribute("type", "text");
            input.setAttribute("placeholder", "Enter the file name");
            input.value = 'content_export';
            body.appendChild(input);

            // Special Character Error
            let errorDiv = document.createElement('div');
            errorDiv.id = 'missingFileName';
            errorDiv.style.display = 'none';
            errorDiv.style.fontSize = '0.75em';
            errorDiv.style.color = categoryBox.warningColor;
            errorDiv.innerHTML = 'Please enter a file name';
            input.focus();
            body.appendChild(errorDiv);
        } else if (type === "contact") {
            contactDescription.setAttribute("type", "text");
            contactDescription.setAttribute("placeholder", "Contact Name/Description");
            let helpBlock = document.createElement("p");
            helpBlock.className = "help-block";
            helpBlock.style.fontSize = "0.75em";
            helpBlock.innerText = "Area code required (e.g. 555-123-4567)";
            contactEmail.setAttribute("type", "text");
            contactEmail.setAttribute("placeholder", "Contact Email");
            contactEmail.className = "msc-input margin-top-reg";
            let errorDiv = document.createElement("div");
            errorDiv.id = "contactError";
            errorDiv.style.display = "none";
            errorDiv.style.color = categoryBox.warningColor;
            errorDiv.className = "margin-top-reg";
            body.appendChild(contactDescription);
            body.appendChild(contactTelephone);
            contactTelephone.setAttribute("type", "tel");
            contactTelephone.setAttribute("id", "phone");
            contactTelephone.setAttribute("placeholder", "Contact Number (text messages)");
            var input = document.querySelector("#phone");            
            phoneController = window.intlTelInput(input, {
                utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.21/js/utils.min.js'
            });
            body.appendChild(helpBlock);
            body.appendChild(contactEmail);
            $(".msc-action").append(errorDiv);
        } else {
            cancelbtn.focus();
        }

        document.addEventListener('keyup', _hide);

        function destroy() {
            // CUSTOM
            $('[data-toggle=popover]').popover('hide');
            closeBtn.removeEventListener('click', destroy);
            okBtn.removeEventListener('click', ok);
            cancelbtn.removeEventListener('click', cancel);
            if (options.dismissOverlay) {
                overlay.removeEventListener("click", destroy);
            }
            document.removeEventListener('keyup', _hide);
            document.body.removeChild(dialog);
        }

        function ok() {
            if (options.onOk !== null) {
                if (type === "prompt") {
                    destroy();
                    options.onOk(input.value);
                }
                // CUSTOM
                else if (type === "category") {
                    if (input.value.length > 0) {
                        $('.msc-ok').text('Saving...').prop('disabled', true);
                        let promises = [];
                        let pictureFileName = '';
                        let audioFileName = '';
                        let hasAudio = false;
                        let videoFileName = '';
                        let hasVideo = false;
                        let errorCount = 0;
                        let callbackOffer = $('#callbackOffer').is(':checked');
                        // Group Categories
                        if (options.isGlobal) {
                            $('#categoriesPanel input[type=checkbox]').each(function (i, ele) {
                                if ($(ele).is(':checked')) {
                                    options.selectedGroupCategories.push($(ele).val());
                                }
                            });
                        }
                        // Validate uploads
                        if (!categoryBox.hasPicture(options)) {
                            if (pictureFile.files !== null && pictureFile.files[0] !== undefined) {
                                if (pictureFile.files[0].type === 'image/tiff' || pictureFile.files[0].type.indexOf('image') === -1) {
                                    $('#pictureError').html('<small>That file type is not supported.</small>');
                                    $('.msc-ok').text('Save').prop('disabled', false);
                                    errorCount++;
                                }
                                else {
                                    $('#pictureError').html('');
                                }
                            }
                        }
                        if (!categoryBox.hasAudio(options)) {
                            if (audioFile.files !== null && audioFile.files[0] !== undefined) {
                                if (!(audioFile.files[0].type === 'audio/mp3' || audioFile.files[0].type === 'audio/mpeg')) {
                                    $('#audioError').html('<small>Only audio MP3 files are accepted.</small>');
                                    $('.msc-ok').text('Save').prop('disabled', false);
                                    errorCount++;
                                }
                                else if (audioFile.files[0].size > 5242880) {
                                    $('#audioError').html('<small>The audio file size is too big.</small>');
                                    $('.msc-ok').text('Save').prop('disabled', false);
                                    errorCount++;
                                }
                                else {
                                    hasAudio = true;
                                    $('#audioError').html('');
                                }
                            }
                        }
                        else {
                            hasAudio = true;
                        }
                        if (!categoryBox.hasVideo(options)) {
                            if (videoFile.files !== null && videoFile.files[0] !== undefined) {
                                if (videoFile.files[0].type !== 'video/mp4') {
                                    $('#videoError').html('<small>Only video MP4 files are accepted.</small>');
                                    $('.msc-ok').text('Save').prop('disabled', false);
                                    errorCount++;
                                }
                                else if (videoFile.files[0].size > 105000000) {
                                    $('#videoError').html('<small>The video file size is too big.</small>');
                                    $('.msc-ok').text('Save').prop('disabled', false);
                                    errorCount++;
                                }
                                else {
                                    hasVideo = true;
                                    $('#videoError').html('');
                                }
                            }
                        }
                        else {
                            hasVideo = true;
                        }
                        if (errorCount > 0) {
                            return;
                        }
                        if (hasAudio && hasVideo) {
                            $('#audioError, #videoError').html('<small>Only an audio file or a video file can be added to an item, not both.</small>');
                            $('.msc-ok').text('Save').prop('disabled', false);
                            return;
                        }
                        if (specialOfferInput.value.length > 0 && (hasAudio || hasVideo)) {
                            $('#specialOfferError').html('<small>Items with a digital coupon cannot have an audio or video file attached.</small>');
                            $('.msc-ok').text('Save').prop('disabled', false);
                            return;
                        }
                        // Upload picture
                        if (!categoryBox.hasPicture(options)) {
                            if (pictureFile.files !== null && pictureFile.files[0] !== undefined) {
                                $('#pictureError').html('');
                                let property = JSON.parse(sessionStorage.getItem('property'));
                                let formData = new FormData();
                                formData.append('file', pictureFile.files[0]);
                                let endpoint;
                                if (options.isGlobal) {
                                    endpoint = '/api/Groups/GlobalContentItems/Files?type=image';
                                }
                                else if (options.containerId == null) {
                                    endpoint = '/api/Users/UploadFile?email=' + encodeURIComponent(property.email) + '&type=image';
                                }
                                else {
                                    endpoint = `/api/Groups/Containers/${options.containerId}/Content/Files?type=image`;
                                }
                                let promise = $.ajax({
                                    url: endpoint,
                                    type: 'POST',
                                    data: formData,
                                    processData: false,
                                    contentType: false,
                                    success: function (data) {
                                        pictureFileName = data;
                                    },
                                    error: function (xhr, ajaxOptions, thrownError) {
                                        $('#pictureError').text('There was an error saving your file, please try again later.');
                                        $('.msc-ok').text('Save').text('Save').prop('disabled', false);
                                        pictureFileName = '';
                                    }
                                });
                                promises.push(promise);
                            }
                        }
                        else {
                            pictureFileName = options.picture;
                        }
                        // Upload audio
                        if (!categoryBox.hasAudio(options)) {
                            if (audioFile.files !== null && audioFile.files[0] !== undefined) {
                                $('#audioError').html('');
                                $('.upload-progress-bar-audio').css('display', 'block');
                                let property = JSON.parse(sessionStorage.getItem('property'));
                                let formData = new FormData();
                                formData.append('file', audioFile.files[0]);
                                let endpoint;
                                if (options.isGlobal) {
                                    endpoint = '/api/Groups/GlobalContentItems/Files?type=audio';
                                }
                                else if (options.containerId == null) {
                                    endpoint = '/api/Users/UploadFile?email=' + encodeURIComponent(property.email) + '&type=audio';
                                }
                                else {
                                    endpoint = `/api/Groups/Containers/${options.containerId}/Content/Files?type=audio`;
                                }
                                let promise = $.ajax({
                                    xhr: function () {
                                        var xhr = new window.XMLHttpRequest();
                                        xhr.upload.addEventListener('progress', function (event) {
                                            if (event.lengthComputable) {
                                                var percentComplete = event.loaded / event.total;
                                                percentComplete = parseInt(percentComplete * 100);
                                                $('.upload-progress-bar-audio').css('width', percentComplete + '%');
                                                if (percentComplete === 100) {
                                                    $('.upload-progress-bar-audio').text('Upload complete, saving file, please wait...');
                                                }
                                                else {
                                                    $('.upload-progress-bar-audio').text('');
                                                }
                                            }
                                        }, false);
                                        return xhr;
                                    },
                                    url: endpoint,
                                    type: 'POST',
                                    data: formData,
                                    processData: false,
                                    contentType: false,
                                    success: function (data) {
                                        audioFileName = data;
                                    },
                                    error: function (xhr, ajaxOptions, thrownError) {
                                        $('.upload-progress-bar-audio').text('There was an error saving your file, please try again later.');
                                        $('.msc-ok').text('Save').text('Save').prop('disabled', false);
                                        audioFileName = '';
                                    }
                                });
                                promises.push(promise);
                            }
                        }
                        else {
                            audioFileName = options.audio;
                        }
                        // Upload video
                        if (!categoryBox.hasVideo(options)) {
                            if (videoFile.files !== null && videoFile.files[0] !== undefined) {
                                $('#videoError').html('');
                                $('.upload-progress-bar-video').css('display', 'block');
                                let property = JSON.parse(sessionStorage.getItem('property'));
                                let formData = new FormData();
                                formData.append('file', videoFile.files[0]);
                                let endpoint;
                                if (options.isGlobal) {
                                    endpoint = '/api/Groups/GlobalContentItems/Files?type=video';
                                }
                                else if (options.containerId == null) {
                                    endpoint = '/api/Users/UploadFile?email=' + encodeURIComponent(property.email) + '&type=video';
                                }
                                else {
                                    endpoint = `/api/Groups/Containers/${options.containerId}/Content/Files?type=video`;
                                }
                                let promise = $.ajax({
                                    xhr: function () {
                                        var xhr = new window.XMLHttpRequest();
                                        xhr.upload.addEventListener('progress', function (event) {
                                            if (event.lengthComputable) {
                                                var percentComplete = event.loaded / event.total;
                                                percentComplete = parseInt(percentComplete * 100);
                                                $('.upload-progress-bar-video').css('width', percentComplete + '%');
                                                if (percentComplete === 100) {
                                                    $('.upload-progress-bar-video').text('Upload complete, saving file, please wait...');
                                                }
                                                else {
                                                    $('.upload-progress-bar-video').text('');
                                                }
                                            }
                                        }, false);
                                        return xhr;
                                    },
                                    url: endpoint,
                                    type: 'POST',
                                    data: formData,
                                    processData: false,
                                    contentType: false,
                                    success: function (data) {
                                        videoFileName = data;
                                    },
                                    error: function (xhr, ajaxOptions, thrownError) {
                                        $('.upload-progress-bar-video').text('There was an error saving your file, please try again later.');
                                        $('.msc-ok').text('Save').text('Save').prop('disabled', false);
                                        videoFileName = '';
                                    }
                                });
                                promises.push(promise);
                            }
                        }
                        else {
                            videoFileName = options.video;
                        }
                        if (promises.length === 0) {
                            destroy();
                            options.onOk(input.value, tags, textarea.value, callbackOffer, specialOfferInput.value, pictureFileName, audioFileName, videoFileName, options.selectedGroupCategories);
                        }
                        else {
                            Promise.all(promises).then(function () {
                                destroy();
                                options.onOk(input.value, tags, textarea.value, callbackOffer, specialOfferInput.value, pictureFileName, audioFileName, videoFileName, options.selectedGroupCategories);
                            });
                        }
                    }
                }
                else if (type === "import") {
                    if (importFile.files === null || importFile.files[0] === undefined) {
                        $('#importFileError').html('Please select a file').css('display', 'block');
                        return;
                    }
                    if (importFile.files[0].type !== 'text/plain') {
                        $('#importFileError').html('Incorrect file type').css('display', 'block');
                        return;
                    }
                    // Do the import
                    let formData = new FormData();
                    formData.append('file', importFile.files[0]);
                    let property = JSON.parse(sessionStorage.getItem('property'));
                    $('.msc-ok, .msc-cancel').prop('disabled', true);
                    $('.msc-ok').text('Importing...');
                    $.ajax({
                        url: '/api/Users/ImportFile?email=' + encodeURIComponent(property.email),
                        type: 'POST',
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function (data) {
                            destroy();
                            options.onOk();
                        },
                        error: function (xhr, ajaxOptions, thrownError) {
                            if (xhr.status === 400) {
                                $('#importFileError').html('Your file could not be imported; please ensure the file contents are in the correct format').css('display', 'block');
                            }
                            else {
                                $('#importFileError').html('An error occurred while importing your file; please try again later').css('display', 'block');
                            }
                            $('.msc-ok, .msc-cancel').prop('disabled', false);
                            $('.msc-ok').text('Import');
                        }
                    });
                }
                else if (type === "export") {
                    let fileName = input.value;
                    if (fileName === undefined || fileName === null || fileName.length === 0) {
                        $('#missingFileName').css('display', 'block');
                    }
                    else {
                        destroy();
                        options.onOk(input.value);
                    }
                }
                else if (type === "contact") {
                    let number = phoneController.getNumber()
                    if (contactDescription.value === undefined || contactDescription.value === null || contactDescription.value.length === 0) {
                        $("#contactError").css("display", "block").html("The contact name/description is required");
                        return;
                    }
                    if (!phoneController.isValidNumber() || !number) {
                        $("#contactError").css("display", "block").html("A valid contact number is required");
                        return;
                    }
                    if (options.emailRequired && (contactEmail.value === undefined || contactEmail.value === null || contactEmail.value.length === 0)) {
                        $("#contactError").css("display", "block").html("The contact email is required");
                        return;
                    }
                    $('.msc-ok, .msc-cancel').prop('disabled', true);
                    $.get("/api/Accounts", function (account) {
                        if (account.contacts === null) {
                            let contacts = [];
                            contacts.push({
                                description: contactDescription.value,
                                textMobilePhone: number,
                                email: contactEmail.value
                            });
                            contactsBox.save(contacts, function (result) {
                                if (!result) {
                                    $("#contactError").css("display", "block").html("There was an error saving your data, please try again later");
                                    $('.msc-ok, .msc-cancel').prop('disabled', false);
                                    return;
                                }
                                destroy();
                                options.onOk(contactDescription.value);
                            });
                        }
                        else {
                            let contacts = JSON.parse(account.contacts);
                            if (contactsBox.contactAlreadyExists(contacts, contactDescription.value)) {
                                $("#contactError").css("display", "block").html(`The contact "${contactDescription.value}" already exists`);
                                $('.msc-ok, .msc-cancel').prop('disabled', false);
                                return;
                            }
                            contacts.push({
                                description: contactDescription.value,
                                textMobilePhone: number,
                                email: contactEmail.value
                            });
                            contactsBox.save(contacts, function (result) {
                                if (!result) {
                                    $("#contactError").css("display", "block").html("There was an error saving your data, please try again later");
                                    $('.msc-ok, .msc-cancel').prop('disabled', false);
                                    return;
                                }
                                destroy();
                                options.onOk(contactDescription.value);
                            });
                        }
                    });
                }
                else {
                    destroy();
                    options.onOk();
                }
            }
            else {
                destroy();
            }
        }

        // CUSTOM
        function convert() {
            destroy();
            options.onConvert(input.value, tag.value, tags, textarea.value, specialOfferInput.value);
        }

        // CUSTOM
        function removePicture() {
            if (options.isGlobal) {
                $('#categoriesPanel input[type=checkbox]').each(function (i, ele) {
                    if ($(ele).is(':checked')) {
                        options.selectedGroupCategories.push($(ele).val());
                    }
                });
            }
            $.ajax({
                url: '/api/Users/DeleteFile?filename=' + options.picture,
                type: 'DELETE',
                success: function () {
                    destroy();
                    options.onOk(input.value, tags, textarea.value, options.callbackOffer, specialOfferInput.value, '', options.audio, options.video, options.selectedGroupCategories);
                }
            });
        }

        // CUSTOM
        function removeAudio() {
            if (options.isGlobal) {
                $('#categoriesPanel input[type=checkbox]').each(function (i, ele) {
                    if ($(ele).is(':checked')) {
                        options.selectedGroupCategories.push($(ele).val());
                    }
                });
            }
            $.ajax({
                url: '/api/Users/DeleteFile?filename=' + options.audio,
                type: 'DELETE',
                success: function () {
                    destroy();
                    options.onOk(input.value, tags, textarea.value, options.callbackOffer, specialOfferInput.value, options.picture, '', options.video, options.selectedGroupCategories);
                }
            });
        }

        // CUSTOM
        function removeVideo() {
            if (options.isGlobal) {
                $('#categoriesPanel input[type=checkbox]').each(function (i, ele) {
                    if ($(ele).is(':checked')) {
                        options.selectedGroupCategories.push($(ele).val());
                    }
                });
            }
            $.ajax({
                url: '/api/Users/DeleteFile?filename=' + options.video,
                type: 'DELETE',
                success: function () {
                    destroy();
                    options.onOk(input.value, tags, textarea.value, options.callbackOffer, specialOfferInput.value, options.picture, options.audio, '', options.selectedGroupCategories);
                }
            });
        }

        function cancel() {
            destroy();
            if (options.onCancel !== null) {
                options.onCancel();
            }
        }

        function _hide(e) {
            if (e.keyCode === 27) {
                destroy();
            }
        }
    }
    var exportData = {
        mscConfirm: function (title, sub, onOk, onCancel) {
            buildUI(title, sub, onOk, onCancel, null, "confirm", 'Confirm');
        },
        mscPrompt: function (title, sub, onOk, onCancel) {
            buildUI(title, sub, onOk, onCancel, null, "prompt");
        },
        // CUSTOM
        mscCategory: function (title, sub, onOk, onCancel, onConvert) {
            buildUI(title, sub, onOk, onCancel, onConvert, "category");
        },
        mscAlert: function (title, sub, onOk, onCancel) {
            buildUI(title, sub, onOk, onCancel, null, "alert");
        },
        // CUSTOM
        mscImport: function (title, sub, onOk, onCancel) {
            buildUI(title, sub, onOk, onCancel, null, "import");
        },
        mscExport: function (title, sub, onOk, onCancel) {
            buildUI(title, sub, onOk, onCancel, null, "export");
        },
        // CUSTOM
        mscContact: function (title, sub, onOk, onCancel) {
            buildUI(title, sub, onOk, onCancel, null, "contact");
        },
        mscClose: function () {
            var prev = document.getElementsByClassName('msc-confirm');
            if (prev.length > 0) {
                document.body.removeChild(prev[0]);
            }
        }
    };
    return exportData;
}));

// CUSTOM
var categoryBox = function () {
    return {
        parentLength: 150,
        childLength: 500,
        warningColor: '#d9534f',
        updateRecommendedCharacters: function (textarea, hasChildren) {
            var recommendedLength = hasChildren ? categoryBox.parentLength : categoryBox.childLength;
            var remainingChars = recommendedLength - textarea.value.length;
            var charCount = $('#charCount');
            var fontColor = 'inherit';
            if (remainingChars < 0) {
                fontColor = categoryBox.warningColor;
                $('.msc-content .warning-icon').css('display', 'inline-block');
            }
            else {
                fontColor = 'inherit';
                $('.msc-content .warning-icon').css('display', 'none');
            }
            textarea.setAttribute('style', 'color: ' + fontColor + ';');
            charCount.css('color', fontColor);
            charCount.text(recommendedLength - textarea.value.length);
        },
        checkForBannedCharacters: function (element) {
            if (element.value.includes('&')) {
                $('#bannedCharacters').css('display', 'block');
                $('.msc-ok').prop('disabled', true);
            }
            else {
                $('#bannedCharacters').css('display', 'none');
                $('.msc-ok').prop('disabled', false);
            }
        },
        addTag: function (tag, tags) {
            // Only add unique tags to the array
            var exists = false;
            for (var i = 0; i < tags.length; i++) {
                if (tags[i] === tag) {
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                tags.push(tag);
            }
            categoryBox.displayTags(tags);
        },
        displayTags: function (tags) {
            $('.tags-panel').text('');
            if (tags !== undefined && tags !== null && tags.length > 0) {
                for (var i = 0; i < tags.length; i++) {
                    $('.tags-panel').append('<div class="pull-left tag-item">' + tags[i] + ' <span class="glyphicon glyphicon-remove" onclick="categoryBox.removeTag(\'' + tags[i].replace(/\'/g, '\\\'') + '\')"></span></div>');
                }
            }
            else {
                $('.tags-panel').text('Tags that have been added will be displayed here');
            }
        },
        removeTag: function (tag) {
            var index = tags.indexOf(tag);
            if (index > -1) {
                tags.splice(index, 1);
            }
            categoryBox.displayTags(tags);
        },
        showMoreOptions: function () {
            $('#moreOptions').toggleClass('show');
            if ($('#moreOptions').hasClass('show')) {
                $('#moreOptionsLink').text('Hide More Options');
            }
            else {
                $('#moreOptionsLink').text('Show More Options');
            }
        },
        hasSpecialOffer: function (options) {
            return options.specialOffer !== undefined && options.specialOffer !== null && options.specialOffer.length > 0;
        },
        hasPicture: function (options) {
            return options.picture !== undefined && options.picture !== null && options.picture.length > 0;
        },
        hasAudio: function (options) {
            return options.audio !== undefined && options.audio !== null && options.audio.length > 0;
        },
        hasVideo: function (options) {
            return options.video !== undefined && options.video !== null && options.video.length > 0;
        },
        isCategoryPreselected: function (ids, id) {
            for (let i = 0; i < ids.length; i++) {
                if (ids[i] === id) {
                    return true;
                }
            }
            return false;
        },
        selectAllCategories: function (checked) {
            $('#categoriesPanel input[type=checkbox]').each(function (i, ele) {
                $(ele).prop('checked', checked);
            });
        }
    };
}();

var contactsBox = function () {
    return {
        save: function (contacts, callback) {
            $.ajax({
                url: "/api/Accounts/UpdateContacts",
                type: "PATCH",
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(contacts),
                success: function () {
                    callback(true);
                },
                error: function (xhr) {
                    if (xhr.status === 200) {
                        callback(true);
                        return;
                    }
                    callback(false);
                }
            });
        },
        contactAlreadyExists: function (contacts, contactDescription) {
            for (let i = 0; i < contacts.length; i++) {
                if (contacts[i].Description.toLowerCase() === contactDescription.toLowerCase()) {
                    return true;
                }
            }
            return false;
        }
    };
}();